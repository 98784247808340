@tailwind base;
@tailwind components;
@tailwind utilities;

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}



.theme__link {
    margin: 1em;
    padding: 1em;
    border-radius: 10px;
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: col;
    align-items: center;
    /* color: #3E3EC9; */
}

.theme__link i {
}

.theme__link-text {
    font-size: 16px;
    font-weight: 700;
    opacity: 0;
    transition: all 0.3s;
    max-width: 0;
    overflow: hidden;
    transition: all 0.3s;
    white-space: nowrap;

}
.theme__link:hover {
    background: #888;
}

#copy-icon, #sep-border {
    opacity: 0;
}

#sep-border {
    width: 0%;
}

#sidebar:hover .theme__link-text {
    margin-left: 1em;
    opacity: 1;
    max-width: 12em;
}

body {
    background: #f7f8fa;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.shadow-hidden {
    box-shadow: 0 0 0 #0000;
}

.float-up {
    opacity: 0
}

.slow-float-up {
    opacity: 0
}

/* ------------------ */
/* Theme Inputs */
/* ----------- */
.theme__form-controller {
    position: relative;
    background: white;
}

textarea.theme__text-field {
    padding-top: 8px;
}

.theme__text-field {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 1em;
    font-size: inherit;
}


.theme__text-field-label {
    position: absolute;
    transform: translateY(-50%);
    pointer-events: none;
    top: 50%;
    left: 1rem;
    background: inherit;
    padding: 0 0.5rem;
    transition: top 200ms ease-in, left 200ms ease-in,
        font-size 200ms ease-in, transform 200ms ease-in;
}

.theme__text-field:focus ~ .theme__text-field-label, 
    .theme__text-field:not(:placeholder-shown).theme__text-field:not(:focus) 
        ~.theme__text-field-label, .theme__text-field-label.active {
    transform: translateY(0);
    top: -0.5rem;
    font-size: 0.8rem;
}

.theme__text-field:focus ~ .theme__text-field-label, .theme__text-field-label.focused {
    color: var(--theme-active);
}

.theme__form-controller .react-datepicker-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
}

.theme__form-controller .react-datepicker__input-container {
    height: 100%;
}

input:read-only, input:read-only ~label{
    --color: #999;
    border-color: var(--color);
    color: var(--color);
}

input:focus:not(:read-only):not(:disabled), textarea:focus:not(:read-only):not(:disabled) {
    /* --color: var(--theme-active); */
    border-color: var(--theme-active);
}

.theme__text-field-label--select {
    /* transform: translateY(30%); */
}

.theme__select-container {
    @apply border-slate-300;
    @apply border;
}
